import { Component, Vue } from "vue-property-decorator";

import { Header,Footer } from "@/layout";
import { ClassModel } from "@/models";
import { Teacher } from "@/types/ModelType";
import { TeCommon } from "../Common";

export class ClassForm {
  city = "";
  school = "";
  name = '';
  student = "";
  teacher: Teacher[] = [];
  clid = '';
}

type response = { [key: string]: unknown };

@Component<ApTeacherClassInfoController>({
  components: {
    Header,Footer
  }
})
export default class ApTeacherClassInfoController extends Vue {
  /*定義data 變數 */
  private doubleClick = false;
  private classForm = new ClassForm();
  private selectTeacherList: Teacher[] = [];
  private errorMsg = "";
  private token = window.localStorage.getItem('teacherToken') as string;

  public async created() {
    const item = {
      clid: this.$route.query.clid,
      token: this.token,
    }
    const editData = await ClassModel.teGet(item) as response;
    this.assignDataToForm(editData);
    this.selectTeacherList = this.classForm.teacher;
  }

  private assignDataToForm(data: response) {
    TeCommon.checkToken(data.ERR_CODE as number);

    const item = data.info as {
      clid: string;
      city: string;
      school: string;
      name: string;
      teacher: Teacher[];
      student: string;
    };
    this.classForm.clid = item.clid;
    this.classForm.city = item.city;
    this.classForm.school = item.school;
    this.classForm.name = item.name;
    this.classForm.teacher = item.teacher;
    this.classForm.student = item.student;
  }

}